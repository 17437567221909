import React from "react";

import "../styles/views/AyudaView.css";

const AyudaView = () => {
    return <div className="AyudaView">
        <h1>Ayuda</h1>
        <h2>Tutoriales</h2>
        <div className="Tutoriales">
            <div>
                <h3>Usabilidad: Graba lo que Quieras</h3>
                <video src="https://godemos-media.s3.us-east-2.amazonaws.com/USABILIDAD_GRABA_LO_QUE_QUIERAS.mp4" controls></video>
            </div>
            <div>
                <h3>Usabilidad: Go-Casting</h3>
                <video src="https://godemos-media.s3.us-east-2.amazonaws.com/USABILIDAD_GO_CASTING.mp4" controls></video>
            </div>
            {/* <div>
                <h3>Usabilidad: CRM</h3>
                <video src="https://godemos-media.s3.us-east-2.amazonaws.com/MARIO_CRM.mp4" controls></video>
            </div> */}
        </div>
        <h2>Tarifas</h2>
        <p>En GoDemos, entendemos la importancia de tener una tarifa justa y competitiva para garantizar una competencia equilibrada en la industria. Por eso, hemos recopilado las tarifas recomendadas por las principales asociaciones de locutores en Latinoamérica.</p>
        <p><button className="standard" onClick={e=>window.location.href="/tarifario"}>Ver Tarifario</button></p>
        <p><b>¿Necesitas más ayuda?</b></p>
        <p>Conoce más detalles y resuelve todas tus dudas en cuanto a planes, precios y soporte técnico en nuestro <a href="https://wa.link/k33yhs" target='_blank' rel='noreferrer'>Whatsapp</a>.</p>
        <p>También nos puedes escribir al correo electrónico <a href="mailto:contacto@godemos.cl" target='_blank' rel='noreferrer'>contacto@godemos.cl</a></p>
    </div>
        ;
};

export default AyudaView;