import React, { useEffect, useRef, useState } from "react";

import "../styles/components/AudioGoDemosComponent.css";
import { DownloadIcon, PlayFillIcon, StopFillIcon, VolumeUpIcon } from "./BootstrapIcons";

const backendRoot = process.env.REACT_APP_BACKEND_ROOT;

/**
 * Renders an audio player component for GoDemos.
 *
 * @param {Object} props - The component props.
 * @param {string} props.src - The source URL of the audio file. Required.
 * @param {Function} [props.callback=null] - The callback function to be called when the audio is loaded. The audio element is passed as an argument.
 * @param {Object} [props.style={}] - The inline styles for the component. Default is {}.
 * @param {Function} [props.onPlay=null] - The callback function to be called when the audio starts playing. The event is passed as an argument.
 * @param {Function} [props.onStop=null] - The callback function to be called when the audio stops playing. The event is passed as an argument.
 * @param {boolean} [props.autoplay=false] - Determines whether the audio should start playing automatically. Default is false.
 * @param {boolean} [props.downloadable=false] - Determines whether the audio should be downloadable. Default is false.
 * @returns {JSX.Element} The rendered AudioGoDemosComponent.
 */
const AudioGoDemosComponent = ({ src, id = null, callback = (audioElement) => { }, style = {}, onPlay = (event) => { }, onStop = (event) => { }, autoplay = false, downloadable = false }) => {
    const [progress, setProgress] = useState(30);
    const [time, setTime] = useState(0);
    const [duration, setDuration] = useState(0); // audio.current.duration
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(100);

    const audio = useRef(null);

    const formatTime = (time) => {
        if (isNaN(time) || !isFinite(time)) return '--:--';
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time % 60);

        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    // set audio event listeners
    useEffect(() => {
        if (!audio.current) return;

        audio.current.addEventListener("loadedmetadata", (e) => {
            setProgress(e.target.currentTime * 100 / e.target.duration);
            setTime(e.target.currentTime);
        });

        audio.current.addEventListener("loadeddata", (e) => {
            setDuration(e.target.duration);
            if (callback) {
                callback(e.target);
            }
        });

        audio.current.addEventListener("timeupdate", (e) => {
            setProgress(e.target.currentTime * 100 / e.target.duration);
            setTime(e.target.currentTime);
        });

        audio.current.addEventListener("ended", (e) => {
            e.target.currentTime = 0;
            setProgress(0);
            setTime(0);
        });

        audio.current.addEventListener("play", () => {
            setPlaying(true);
            if (onPlay) onPlay();
        });

        audio.current.addEventListener("pause", (e) => {
            setPlaying(false);
            e.target.currentTime = 0;
            setProgress(0);
            setTime(0);
            if (onStop) onStop();
        });
        // eslint-disable-next-line
    }, [audio]);

    useEffect(() => {
        if (audio.current) {
            audio.current.volume = volume / 100;
        }
    }, [volume]);

    useEffect(() => {
        if (!autoplay) setPlaying(false);
    }, [src]);

    const source = src.startsWith("blob") ? src : (src.startsWith("http") ? `${src}` : (src.startsWith("/") ? `${backendRoot}${src}` : `${backendRoot}/${src}`));

    if(id==="")id=Math.random().toString(36).substring(7);

    return (
        <>
            <audio id={id} src={`${source}`} controls ref={audio} style={{ display: 'none' }} autoPlay={autoplay}></audio>

            {audio.current && <div className="AudioGoDemosComponent" style={style}>
                <div className="AudioGoDemosComponent-bar">
                    <div className="AudioGoDemosComponent-bar-progress"
                        style={{ width: `${progress}%` }}
                        onResize={(e) => {
                            console.log(e);
                        }}
                    ></div>
                    {isFinite(duration) && <input type="range" min="0" max="100" value={progress} className="AudioGoDemosComponent-bar-range" onChange={(e) => {
                        audio.current.currentTime = e.target.value * audio.current.duration / 100;
                        setProgress(e.target.value);
                    }} />}
                </div>

                <div className="AudioGoDemosComponent-controls">
                    <span>{formatTime(time)}</span>
                    {!playing && <PlayFillIcon onClick={() => { audio.current.play() }} size="24" />}
                    {playing && <StopFillIcon onClick={() => { audio.current.pause() }} size="24" />}
                    <span>{isFinite(duration) && formatTime(duration)}</span>
                </div>

                <div className="AudioGoDemosComponent-volume">
                    <VolumeUpIcon size="24" />
                    <input type="range" min="0" max="100" value={volume} className="AudioGoDemosComponent-volume-range" onChange={(e) => {
                        setVolume(e.target.value);
                    }} />
                    {downloadable && <a download={'demo.mp3'} rel="noreferrer" target="_blank" href={`${source}`} style={{ color: 'inherit' }}><DownloadIcon size="24" /></a>}
                </div>
            </div>}
        </>
    );
};

export default AudioGoDemosComponent;