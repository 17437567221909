import React from "react";

import '../styles/views/DescuentoView.css';
import { serviceFetch } from "../utils/serviceFetch";
// import { VentasProvider } from "../contexts/VentasContext";
import { useFetch } from "../hooks/useFetch";
import { useParams } from "react-router-dom";

const DescuentoView = () => {

    const { codigo } = useParams();

    const { data: descuento, httpStatus } = useFetch(`v1/descuento/${codigo}`, {}, []);

    const handlePaypalPayment = (id, nombre) => {
        const formdata = new FormData();
        formdata.append("plan_id", id);
        formdata.append("nombre", nombre);
        formdata.append("payment_method", "paypal");
        serviceFetch("v1/subscriptions/subscribe/", "POST", formdata).then(r => r.json()).then(json => {
            window.location.href = json.url;
        });
    };

    const handleFlowPayment = (id, nombre) => {
        const formdata = new FormData();
        formdata.append("plan_id", id);
        formdata.append("nombre", nombre);
        formdata.append("payment_method", "flow");
        serviceFetch("v1/subscriptions/subscribe/", "POST", formdata).then(r => r.json()).then(json => {
            if (json.url)
                window.location.href = json.url;
            else if (json.mensaje)
                alert(json.mensaje);
        });
    };


    return (
        <div className="DescuentoView">
            <h1>¡Oferta Imperdible!</h1>

            <div className="DescuentoViewContent">
                {descuento && (httpStatus===200 || httpStatus===201) && <>
                    <div className="Resumen">
                        <h2>Total</h2>
                        <h1>{descuento.producto.precio} {descuento.producto.moneda.nombre}</h1>
                        <p>Aviso: Para asegurar el proceso de pago, es necesario que tu correo electrónico funcione correctamente.</p>
                        
                        <div className="MediosDePago">
                            {descuento.paypal_enabled && <div><button className="standard" onClick={e => handlePaypalPayment(descuento.producto.paypal_id,descuento.producto.nombre)}>Pagar con PayPal</button></div>}
                            {descuento.flow_enabled && <div><button className="standard" onClick={e => handleFlowPayment(descuento.producto.flow_id,descuento.producto.nombre)}>Pagar con Flow</button></div>}
                        </div>
                    </div>

                    <div>
                        <h2>{descuento.producto.nombre}</h2>
                        <p>{descuento.producto.descripcion}</p>
                        <div>Descuento: -{Math.floor(descuento.porcentaje)}%</div>
                        <div>{descuento.condicion}</div>
                    </div>
                </>}
                {descuento && httpStatus===403 && <div className="Mensaje">{descuento.message}</div>}
            </div>
        </div>
    );
};

export default DescuentoView;