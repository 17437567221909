import React, { useEffect } from "react";
import { PublicProfileContext, PublicProfileProvider } from "../contexts/PublicProfileContext";
import "../styles/views/PublicProfileView.css";
import { CassetteIcon, EnvelopeAtIcon, HomeIcon, MusicNoteListIcon, PathCheckIcon } from "../components/BootstrapIcons";
import AudioGoDemosComponent from "../components/AudioGoDemosComponent";

const PublicProfileView = () => {

    const enableResponsiveness = () => {
        // Verifica si ya existe una etiqueta viewport
        let viewportMeta = document.querySelector('meta[name="viewport"]');

        if (!viewportMeta) {
            // Si no existe, crea una nueva etiqueta meta
            viewportMeta = document.createElement('meta');
            viewportMeta.name = "viewport";
            viewportMeta.content = "width=device-width, initial-scale=1";
            document.head.appendChild(viewportMeta);
        } else {
            // Si existe, actualiza su contenido
            viewportMeta.content = "width=device-width, initial-scale=1";
        }
    };

    useEffect(() => {
        enableResponsiveness();
        document.querySelector('.MenuTop-CerrarSesion')?.remove();
    }, []);
    const [PERFIL, DEMOTECA] = [0, 1];

    const [vista, setVista] = React.useState(PERFIL);

    return (
        <PublicProfileProvider>
            <div className="PublicProfileView">
                {vista === PERFIL && <ProfileCard />}
                {vista === DEMOTECA && <Demoteca />}
                <nav>
                    <ul>
                        <li className={vista === PERFIL ? "active" : "inactive"} onClick={e => setVista(PERFIL)}><HomeIcon />&nbsp;Perfil</li>
                        <li className={vista === DEMOTECA ? "active" : "inactive"} onClick={e => setVista(DEMOTECA)}><CassetteIcon />&nbsp;Demoteca</li>
                        {/* <li onClick={e => setVista(DEMOS)}><SpeakerIcon /></li> */}
                    </ul>
                </nav>
            </div>
        </PublicProfileProvider>
    );
};

const ProfileCard = () => {

    const backendRoot = process.env.REACT_APP_BACKEND_ROOT;

    const { username, profileData, misDemosLoading, misDemosData, profileHttpStatus } = React.useContext(PublicProfileContext);
    const [miDemo, setMiDemo] = React.useState(null);

    const cargarDemo = (demo) => {
        const audio = document.getElementById('demo_listener');
        if(audio){
            audio.pause();
        }
        setMiDemo(demo);
    };

    return (<>
        {profileData && profileHttpStatus===200 && <div className="ProfileCard">
            <div>
                <img src={`${backendRoot}${profileData.avatar}`} alt="Avatar" />
                <h2>{profileData.nombre}</h2>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><b>{username}</b>&nbsp;{profileData.verificado && <PathCheckIcon style={{ color: "var(--green-9)" }} />}</div>

                <p>{profileData.descripcion}</p>
                {profileData.links && <a href={profileData.links} target="_blank" rel="noreferrer">{profileData.links}</a>}
                <div className="EnviarCorreo">
                    <div><a href={`mailto:${username}`} target="_blank" rel="noopener noreferrer"><EnvelopeAtIcon /></a></div>
                </div>
            </div>
            <div>
                <div className="cajas">
                    <div>
                        <b>Equipo de Grabación</b>
                        <p>{profileData.equipo}</p>
                    </div>
                    <div>
                        <b>Tiempo de Entrega</b>
                        <p>{profileData.tiempo_respuesta}</p>
                    </div>
                    <div>
                        <b>Nacionalidad</b>
                        <p>{profileData.pais.nombre}</p>
                    </div>
                    <div>
                        <b>Voz</b>
                        <p>{profileData.voz === "M" ? "Masculina" : "Femenina"}</p>
                    </div>
                </div>
                <div className="PublicMisDemos">
                    <h1>Reels / Demos</h1>
                    <p>Aquí podrás escuchar mis demos subidos a la plataforma</p>
                    {misDemosLoading && <p>Cargando...</p>}
                    {misDemosData && <div className="PublicMisDemos-list">
                        {misDemosData.demoCollection.map(demo => <div className={(miDemo?.id === demo.id) ? "active" : ""} key={demo.id} onClick={e => cargarDemo(demo)}>
                            <MusicNoteListIcon /> {demo.nombre}
                        </div>)}
                    </div>}
                    {miDemo && <AudioGoDemosComponent id="demo_listener" src={`${miDemo.url}`} />}
                </div>
            </div>
        </div>
        }
        {profileData && profileHttpStatus===403 && <div className="ProfileCard">
            <div>
                <h2>Este Perfil no está Habilitado</h2>
                <p>{profileData.message}</p>
            </div>
        </div>}
    </>
    );
};

const Demoteca = () => {

    const { demo, setDemo, tipos, edades, emociones, demosData } = React.useContext(PublicProfileContext);

    const [tipo, setTipoState] = React.useState(null);
    const [edad, setEdadState] = React.useState(null);
    const [emocion, setEmocionState] = React.useState(null);
    const [showTipo, setShowTipo] = React.useState(false);
    const [showEdad, setShowEdad] = React.useState(false);
    const [showEmocion, setShowEmocion] = React.useState(false);

    const setTipo = (t) => {
        if (tipo?.id === t.id)
            setTipoState(null);
        else
            setTipoState(t);
    };

    const setEdad = (e) => {
        if (edad?.id === e.id)
            setEdadState(null);
        else
            setEdadState(e);
    };

    const setEmocion = (e) => {
        if (emocion?.id === e.id)
            setEmocionState(null);
        else
            setEmocionState(e);
    };

    const updateFilterColors = () => {

        if(!demosData || !demosData.configuracion) return;
        let demos = demosData.configuracion.audioLocutorCollection;

        if(tipo)
            demos = demos.filter(demo => demo.tipo.id === tipo.id);
        
        if(edad)
            demos = demos.filter(demo => demo.edad.id === edad.id);

        if(emocion)
            demos = demos.filter(demo => demo.emocion.id === emocion.id);

        let lis = document.querySelectorAll('.filtros ul li');
        lis.forEach(li => {
            li.style.color = "#AAA";
        });

        demos.forEach(demo => {
            document.getElementById(`tipo_${demo.tipo.id}`).style.color = "";
            document.getElementById(`edad_${demo.edad.id}`).style.color = "";
            document.getElementById(`emocion_${demo.emocion.id}`).style.color = "";
        });

    };

    useEffect(() => {
        updateFilterColors();
        if (!tipo || !edad || !emocion) return;

        const aux = demosData.configuracion.audioLocutorCollection.filter(demo => {
            return demo.tipo.id === tipo.id && demo.edad.id === edad.id && demo.emocion.id === emocion.id;
        });
        if (aux.length > 0) {
            setDemo(aux[0]);
        } else {
            setDemo(null);
        }

    }, [tipo, edad, emocion, setDemo, demosData]);

    return (
        <div className="PublicDemoteca">
            <h1>Demoteca</h1>
            <div className="filtros">
                <div>
                    <button className={tipo ? "activo" : ""} onClick={e => setShowTipo(!showTipo)}>{tipo ? `Estilo: ${tipo.nombre}` : 'Estilo'}</button>
                    {<ul className={showTipo ? "show" : "dont"}>
                        {tipos.map(t => <li id={`tipo_${t.id}`} className={(tipo?.id === t.id) ? "active" : "inactive"} key={t.id} onClick={e => setTipo(t)}>{t.nombre}</li>)}
                    </ul>}
                </div>
                <div>
                    <button className={edad ? "activo" : ""} onClick={e => setShowEdad(!showEdad)}>{edad ? `Edad: ${edad.nombre}` : 'Edad'}</button>
                    {<ul className={showEdad ? "show" : "dont"}>
                        {edades.map(elem => <li id={`edad_${elem.id}`} className={(edad?.id === elem.id) ? "active" : ""} key={elem.id} onClick={e => setEdad(elem)}>{elem.nombre}</li>)}
                    </ul>}
                </div>
                <div>
                    <button className={emocion ? "activo" : ""} onClick={e => setShowEmocion(!showEmocion)}>{emocion ? `Emoción: ${emocion.nombre}` : 'Emoción'}</button>
                    {<ul className={showEmocion ? "show" : "dont"}>
                        {emociones.map(elem => <li id={`emocion_${elem.id}`} className={(emocion?.id === elem.id) ? "active" : ""} key={elem.id} onClick={e => setEmocion(elem)}>{elem.nombre}</li>)}
                    </ul>}
                </div>
            </div>
            {tipo && edad && emocion && <div className="demo">
                {demo ? <AudioGoDemosComponent src={`${demo.path_mezclado}`} style={{ width: "350px" }} /> : "No hay demo disponible"}
            </div>}
        </div>
    );
};

export default PublicProfileView;