import React from "react";
import '../styles/MenuLeftView.css';
import { PeopleIcon, HomeIcon, SoundwaveIcon, MicIcon, CassetteIcon, PieChartIcon, SpeakerIcon, VoFillIcon, ListIcon, CashCoinIcon } from "../components/BootstrapIcons";
import { useFetch } from "../hooks/useFetch";
import { Link } from "react-router-dom";

function MenuLeftContainer({ isAuth, activity, setActivity }) {

  const { data, loading, error } = useFetch("left_view/");

  const [collapsed, setCollapsed] = React.useState(false);

  const collapsedStyle = {
    paddingLeft: '16px',
    paddingRight: '16px'
  };

  return (
    <nav className="MenuLeftView">
      {loading && <p>Cargando...</p>}
      {error && <p>Error...</p>}
      {isAuth && <ul>
        <li onClick={() => setCollapsed(!collapsed)} style={collapsed ? collapsedStyle : {}}><ListIcon /></li>
        {data && data.activities.map((item, index) => (
          <li key={index} className={activity === item.name ? "selected" : ""} style={collapsed ? collapsedStyle : {}}>
            <Link to={`/${item.name}`} onClick={() => setActivity(item.name)}>
              <ActivityIcon name={item.name} />
              <span style={collapsed ? { display: 'none' } : {}}>{item.text}</span>
            </Link>
          </li>
        ))
        }
        
        <li className={activity === "compras" ? "selected" : ""} style={collapsed ? collapsedStyle : {}}>
          <Link to="/compras" onClick={() => setActivity("compras")}>
            <ActivityIcon name={"compras"} />
            <span style={collapsed ? { display: 'none' } : {}}>{'Compras'}</span>
          </Link>
        </li>
      </ul>
      }
    </nav>
  );
}

const ActivityIcon = ({ name: activityName }) => {
  return (
    <>
      {activityName === "welcome" && <HomeIcon />}
      {activityName === "locutores" && <PeopleIcon />}
      {activityName === "demos" && <SoundwaveIcon />}
      {activityName === "audios" && <MicIcon />}
      {activityName === "record" && <CassetteIcon />}
      {activityName === "onerecord" && <SoundwaveIcon />}
      {activityName === "crm" && <PieChartIcon />}
      {activityName === "reels" && <SpeakerIcon />}
      {activityName === "casting" && <VoFillIcon />}
      {activityName === "compras" && <CashCoinIcon />}
    </>
  )
};

export default MenuLeftContainer;