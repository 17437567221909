import React from "react";
import { SubscriptionsContext } from "../contexts/SubscriptionsContext";
import { serviceFetch } from "../utils/serviceFetch";
import MedioDePagoContainer from "../containers/MedioDePagoContainer";
import { ProfileContext } from "../contexts/ProfileContext";

const SubscriptionsList = () => {
    const { planes } = React.useContext(SubscriptionsContext);
    const [showActiveSub, setShowActiveSub] = React.useState(false);

    const { locutorPerfil } = React.useContext(ProfileContext);

    const handlePaypal = (plan) => {
        const formdata = new FormData();
        formdata.append("plan_id", plan.id);
        formdata.append("nombre", plan.nombre);
        formdata.append("payment_method", "paypal");
        serviceFetch("v1/subscriptions/subscribe/", "POST", formdata).then(r => r.json()).then(json => {
            window.location.href = json.url;
        });
    };


    const handleFlow = (plan) => {
        const formdata = new FormData();
        formdata.append("plan_id", plan.id);
        formdata.append("nombre", plan.nombre);
        formdata.append("payment_method", "flow");
        serviceFetch("v1/subscriptions/subscribe/", "POST", formdata).then(r => r.json()).then(json => {
            if (json.url)
                window.location.href = json.url;
            else if (json.mensaje)
                alert(json.mensaje);
        });
    };

    return (
        <>
            <h1>Suscripciones</h1>
            {locutorPerfil && locutorPerfil.plan !== "free" &&
                <p>Ya tienes un plan contratado{/* , <a href="#sub" onClick={e => setShowActiveSub(true)}>haz click aquí para ver el detalle</a> */}. Si deseas cambiar tu tipo de suscripción, contáctate con nuestro equipo de soporte.</p>
            }
            {showActiveSub && <MedioDePagoContainer handleClose={e => setShowActiveSub(false)} />}
            {locutorPerfil && locutorPerfil.plan === "free" &&
                <div class="ProductosList">
                    <div>
                        <div><b>Nombre</b></div>
                        <div><b>Descripción</b></div>
                        <div><b>Precio</b></div>
                        <div><b>Moneda</b></div>
                    </div>
                    {planes && planes.map((plan, index) => (<div key={index}>
                        <div>{plan.nombre}</div>
                        <div>{plan.descripcion}</div>
                        <div>{plan.precio?.toLocaleString()}</div>
                        <div>USD</div>
                        <div>
                            <button onClick={e => handlePaypal(plan)}>Paypal</button>
                            <button onClick={e => handleFlow(plan)}>Flow</button>
                        </div>
                    </div>))}
                </div>
            }
        </>
    );
};

export default SubscriptionsList;