import React from "react";
import '../styles/views/ComprasView.css';
import ComprasListComponent from "../components/ComprasListComponent";
import { VentasProvider } from "../contexts/VentasContext";
import ProductosListComponent from "../components/ProductosListComponent";
import { ProductosProvider } from "../contexts/ProductosContext";
import { ProfileProvider } from "../contexts/ProfileContext";
import { SubscriptionsProvider } from "../contexts/SubscriptionsContext";
import SubscriptionsList from "../components/SubscriptionsList";


const ComprasView = () => {

    // const compra = React.useContext(CompraContext);

    return (
        <div className="ComprasView">
            <ProfileProvider>
                <VentasProvider>
                    <ProductosProvider>
                        <ProductosListComponent />
                    </ProductosProvider>
                    <SubscriptionsProvider>
                        <SubscriptionsList />
                    </SubscriptionsProvider>
                    <ComprasListComponent />
                </VentasProvider>
            </ProfileProvider>
        </div>
    );
};




export default ComprasView;