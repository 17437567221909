import React from "react";
import { useFetch } from "../hooks/useFetch";

export const SubscriptionsContext = React.createContext();

export const SubscriptionsProvider = ({ children }) => {
    
    const {data: subscriptions} = useFetch("v1/subscriptions/");

    const { data: planes } = useFetch("v1/voice-plans/");

    return (
        <SubscriptionsContext.Provider value={{ subscriptions, planes }}>
            {children}
        </SubscriptionsContext.Provider>
    );
}