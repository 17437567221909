import React from "react";
import { DetalleVentaContext, DetalleVentaProvider, VentasContext } from "../contexts/VentasContext";
import { CloseIcon, FileDocIcon } from "./BootstrapIcons";
import { serviceFetch } from "../utils/serviceFetch";

const ComprasListComponent = () => {

    const { ventas, loading, error, venta, setVenta } = React.useContext(VentasContext);

    return (
        <>
            <h1>Compras</h1>
            <div className="ComprasList">
                {loading && <p>Cargando historial de compras...</p>}
                {error && <p>Error...{console.log(error)}</p>}
                {ventas && <>
                    <div>
                        <div>Glosa</div>
                        <div>Estado</div>
                        <div>Total</div>
                        <div>Moneda</div>
                        <div>Medio de Pago</div>
                        <div>Fecha</div>
                    </div>
                    {ventas.map((venta) => (
                        <div key={venta.id}>
                            <div>{venta.glosa}</div>
                            <div>{venta.estado_name}</div>
                            <div>{venta.total?.toLocaleString()}</div>
                            <div>{venta.moneda?.nombre}</div>
                            <div>{venta.medio_de_pago_name}</div>
                            <div>{new Date(venta.fecha).toLocaleDateString()}</div>
                            <div><i onClick={e => setVenta(venta)}><FileDocIcon /></i></div>
                        </div>
                    ))}
                </>}
            </div>

            {venta &&
                <DetalleVentaProvider venta={venta}>
                    <CompraDetalle />
                </DetalleVentaProvider>
            }
        </>
    );
};

const CompraDetalle = ({ custom_detalle = null }) => {
    const { venta, setVenta, refreshVentas } = React.useContext(VentasContext);
    const { detalle, loading, error } = React.useContext(DetalleVentaContext);

    const handleCancelarCompra = () => {
        serviceFetch(`v1/ventas/cancel-order/${venta.id}/`, "GET")
            .then(r => r.json())
            .then(json => {
                console.log(json);
                refreshVentas();
                setVenta(null);
            });
    };

    const handlePaypalPayment = () => {
        serviceFetch(`v1/ventas/pago-paypal/${venta.id}/`)
            .then(r => r.json())
            .then(json => {
                if(json.status==="ok")
                    window.location.href = json.url;
                else{
                    alert("Error al solicitar el pago");
                    console.log(json);
                }
            });
    };

    const handleFlowPayment = () => {
        serviceFetch(`v1/ventas/pago-flow/${venta.id}/`)
            .then(r => r.json())
            .then(json => {
                if(json.status==="ok")
                    window.location.href = json.url;
                else{
                    alert("Error al solicitar el pago");
                    console.log(json);
                }
            });
    };

    return (
        <>
            {venta && <div className="CompraDetalle">
                <div><i><CloseIcon onClick={e => setVenta(null)} /></i></div>
                <h2>Detalle de compra</h2>
                <div>{venta.estado_name}</div>

                <div>Medio de Pago: {venta.medio_de_pago_name}</div>
                <div>{venta.fecha && new Date(venta.fecha).toLocaleString()}</div>
                <h3>Detalle</h3>
                <div className="CompraDetalleList">
                    {loading && <p>Cargando detalle...</p>}
                    {error && <p>Error...{console.log(error)}</p>}
                    {detalle && detalle.map((item) => (
                        <div key={item.id}>
                            <div>{item.producto?.nombre}</div>
                            <div>{item.cantidad}</div>
                            <div>{item.precio?.toLocaleString()}</div>
                            <div>{item.moneda.nombre}</div>
                        </div>
                    ))}
                    <div>
                        <div></div>
                        <div>Total</div>
                        <div>{venta.total?.toLocaleString()}</div>
                        <div>{venta.moneda?.nombre}</div>
                    </div>
                </div>
                {venta.estado === "pendiente" &&
                    <div>
                        <button onClick={e => handlePaypalPayment()}>Pagar con PayPal</button>&nbsp;
                        <button onClick={e => handleFlowPayment()}>Pagar con Flow</button>&nbsp;
                        <button onClick={handleCancelarCompra}>Cancelar Compra</button>
                    </div>}
            </div>
            }
            
        </>
    );
};



export default ComprasListComponent;