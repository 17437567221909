import React from "react";
import { ProductosContext } from "../contexts/ProductosContext";
import { Cart3Icon, CartPlusIcon, CloseIcon, DashCircleIcon, PlusCircleIcon } from "./BootstrapIcons";
import { CompraContext } from "../contexts/CompraContext";
import { serviceFetch, serviceHttpRequest } from "../utils/serviceFetch";
import { VentasContext } from "../contexts/VentasContext";
import MedioDePagoContainer from "../containers/MedioDePagoContainer";

const ProductosListComponent = () => {
    const { productos, loading, error } = React.useContext(ProductosContext);
    const [showActiveSub, setShowActiveSub] = React.useState(false);

    const compraContext = React.useContext(CompraContext);

    const checkDescuento = () => {
        const descuento = document.getElementById("descuento").value;
        if (descuento) {
            serviceFetch(`v1/descuento/check/${descuento}`)
                .then(r => r.json())
                .then(json => {
                    console.log(json);
                    if (json.available) {
                        window.location.href = `/descuento/${descuento}`;
                    }else{
                        alert(json.message);
                    }
                    
                });
        }
    };

    const addProducto = (id, producto) => {
        compraContext.addProducto(producto);
        const e = document.getElementById(id);
        e.style.animation = "greenbright 0.5s";
        setTimeout(() => {
            e.style.animation = "";
        }, 500);
    };

    return (
        <>
            <h1>Productos {compraContext.detalle.length > 0 && <button className="ver-compra" onClick={e => compraContext.setShow(true)}><Cart3Icon /> Ver Compra ({compraContext.detalle.reduce((acc, item) => acc + item.cantidad, 0)})</button>}</h1>
            <div className="ProductosList">
                {loading && <p>Cargando productos...</p>}
                {error && <p>Error...{console.log(error)}</p>}
                {productos &&
                    <>
                        <div>
                            <div><b>Nombre</b></div>
                            <div><b>Descripción</b></div>
                            <div><b>Precio</b></div>
                            <div><b>Moneda</b></div>
                        </div>
                        {productos.filter(p => p.tipo_pago === "unico").map((producto) => (
                            <div key={producto.id} id={`producto-${producto.id}`}>
                                <div>{producto.nombre}</div>
                                <div>{producto.descripcion}</div>
                                <div>{producto.precio?.toLocaleString()}</div>
                                <div>{producto.moneda?.nombre}</div>
                                <div><i onClick={e => addProducto(`producto-${producto.id}`, producto)}><CartPlusIcon size="28px" /></i></div>
                            </div>
                        ))}
                    </>
                }
            </div>
            
            <h2>¿Tienes un cupón de canje?</h2>
            <div className="AplicarCupon"><input type="text" id="descuento" /><button onClick={e => checkDescuento()}>Aplicar</button></div>
            {compraContext.show && <CarroDeCompras />}
            {showActiveSub && <MedioDePagoContainer handleClose={e => setShowActiveSub(false)} />}
        </>
    );
};

const CarroDeCompras = () => {

    const { compra, detalle, removeProducto, addProducto, setShow, resetCompra } = React.useContext(CompraContext);
    const { refreshVentas, setVenta } = React.useContext(VentasContext);

    const handleConfirmar = e => {
        const body = {
            "fecha": new Date().toISOString(),
            "total": compra.total,
            "medio_de_pago": "manual",
            "estado": "pendiente",
            "glosa": "Compra de productos",
            "codigo": `C${new Date().getTime()}`,
            "detalles": detalle.map((item) => {
                return {
                    "producto": item.producto.id,
                    "cantidad": item.cantidad,
                    "precio": item.precio,
                    "moneda": item.moneda.id
                }
            }),
            "moneda": compra.moneda.id
        };

        serviceHttpRequest("v1/ventas/", { "method": "POST", "body": JSON.stringify(body), }, null, null, "application/json")
            .then(json => {
                console.log(json);
                setVenta(json);
                refreshVentas();
                setShow(false);
                resetCompra();
            });
    };

    const handleRemoveProducto = (producto) => {
        removeProducto(producto);
    };

    const handleAddProducto = (producto) => {
        addProducto(producto);
    };

    return (
        <div className="CompraDetalle">
            <div><i><CloseIcon onClick={e => setShow(false)} /></i></div>
            <h2>Detalle de compra</h2>
            <div>{compra.estado_name}</div>

            <div>Medio de Pago: {compra.medio_de_pago_name}</div>
            <div>{compra.fecha && new Date(compra.fecha).toLocaleString()}</div>
            <h3>Detalle</h3>
            <div className="CompraDetalleList">
                {detalle.map((item, index) => (
                    <div key={item.id}>
                        <div>{item.producto?.nombre}</div>
                        <div><PlusCircleIcon size="14px" onClick={e => handleAddProducto(item.producto)} />&nbsp;{item.cantidad}&nbsp;<DashCircleIcon size="14px" onClick={e => handleRemoveProducto(item.producto)} /></div>
                        <div>{(item.precio*item.cantidad).toLocaleString()}</div>
                        <div>{item.moneda.nombre}</div>
                    </div>
                ))}
                <div>
                    <div></div>
                    <div><b>Total</b></div>
                    <div>{compra.total?.toLocaleString()}</div>
                    <div>{compra.moneda?.nombre}</div>
                </div>
            </div>
            <div>{detalle.length > 0 && <button onClick={handleConfirmar}>Confirmar Compra</button>}</div>
        </div>
    );
}

export default ProductosListComponent;